.connect-con {
  z-index: 1000;
  background-color: #000000bf;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.connect-con .connect-pannel {
  border: 2px solid var(--White-100, #fff);
  background: var(--Black, #000);
  border-radius: 16px;
  width: 360px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.connect-con .connect-pannel .connect-header {
  color: #fff;
  border-bottom: 1.5px solid var(--White-100, #fff);
  height: 60px;
  padding: 0 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.connect-con .connect-pannel .connect-header svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.connect-con .connect-pannel .connect-header .connect-header-title {
  margin-left: 12px;
}

.connect-con .connect-pannel .connect-header .connect-header-close svg {
  width: 12px;
  height: 12px;
}

.connect-con .connect-pannel .connect-item-con {
  padding: 16px;
}

.connect-con .connect-pannel .connect-item {
  cursor: pointer;
  text-align: left;
  background: #22252d;
  border: 2px solid #fff;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 14px;
}

.connect-con .connect-pannel .connect-item:hover {
  border-color: #3671ee;
}

.connect-con .connect-pannel .connect-item.disabled {
  cursor: not-allowed;
  opacity: .3;
  border-color: #fff;
}

.connect-con .connect-pannel .connect-item img {
  width: 44px;
  height: 44px;
  margin-right: 14px;
}

.connect-con.light svg {
  color: #282828;
  fill: #282828;
}

.connect-con.light svg path {
  stroke: #282828;
}

.connect-con.light .connect-pannel {
  background: var(--White, #fff);
}

.connect-con.light .connect-pannel .connect-header {
  color: var(--Black, #282828);
  border-color: var(--Black-100, #282828);
}

.connect-con.light .connect-pannel .connect-item {
  border-color: var(--Black-100, #282828);
  background: #fff;
}

.connect-con.light .connect-pannel .connect-item:hover {
  border-color: #3671ee;
}

.connect-con.light .connect-pannel .connect-item.disabled {
  opacity: .7;
  border-color: var(--Black-100, #282828);
}

/*# sourceMappingURL=index.d314cc5a.css.map */
