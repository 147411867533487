.connect-con {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    top: 0;
    .connect-pannel {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        border-radius: 16px;
        border: 2px solid var(--White-100, #fff);
        background: var(--Black, #000);
        .connect-header {
            font-weight: 700;
            color: #fff;
            height: 60px;
            border-bottom: 1.5px solid var(--White-100, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            padding: 0 16px;
            svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            .connect-header-title {
                margin-left: 12px;
            }
            .connect-header-close {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .connect-item-con {
            padding: 16px;
        }
        .connect-item {
            cursor: pointer;
            // width: 100%;
            text-align: left;
            padding: 14px;
            border-radius: 12px;
            border: 2px solid #fff;
            background: #22252d;
            margin-bottom: 16px;
            &:hover {
                border-color: #3671ee;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.3;
                border-color: #fff;
            }
            img {
                width: 44px;
                height: 44px;
                margin-right: 14px;
            }
        }
    }
    &.light {
        svg {
            color: #282828;
            fill: #282828;
            path {
                stroke: #282828;
            }
        }
        .connect-pannel {
            background: var(--White, #fff);
            .connect-header {
                color: var(--Black, #282828);
                border-color: var(--Black-100, #282828);
            }
            .connect-item {
                background: #fff;
                border-color: var(--Black-100, #282828);
                &:hover {
                    border-color: #3671ee;
                }
                &.disabled {
                    opacity: 0.7;
                    border-color: var(--Black-100, #282828);
                }
            }
        }
    }
}
